import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import Loading from "../components/Loading";
import { post } from "./api";

const customStyles = {
  headRow: {
    style: {
      border: "none",
      backgroundColor: "rgba(248, 249, 249, 1)",
    },
  },
  headCells: {
    style: {
      color: "#202124",
      fontSize: "14px",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "rgb(230, 244, 244)",
      borderBottomColor: "#FFFFFF",
      borderRadius: "0px",
      outline: "1px solid #FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

const DataTablePagination = React.memo(
  ({
    title,
    columns,
    apiUrl,
    queryObj,
    pageName,
    updateState = false,
  }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [perPage, setPerPage] = useState(10);
   
    const [totalRows, setTotalRows] = useState();
    const [showPagination, setShowPagination] = useState(false);
    const query = queryObj;

    const fetchUsers = async (page, perPage, query) => {
      setLoading(true);
      try {
        const response = await post(`${apiUrl}`, {
          options: {
            page: page,
            limit: perPage,
          },
          query: query,
        });
        if (response?.data?.docs) {
          setData(response.data.docs);
          setTotalRows(response.data.totalDocs);
          setShowPagination(response.data.totalDocs > perPage);
        } else {
          setData(response.data);
          setShowPagination(false);
        }
        setLoading(false)
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false)
      }
    };

    const handlePageChange = (page) => {
      fetchUsers(page, perPage, query);
    };

    const handlePerRowsChange = async (newPerPage) => {
      setPerPage(newPerPage);
      fetchUsers(1, newPerPage, query);
    };

    useEffect(() => {
      fetchUsers(1, perPage, queryObj);
    }, [queryObj, perPage, updateState]); // Only re-fetch data when queryObj changes

    // useEffect(() => {
    //   fetchUsers(1, perPage, query);
    // }, [perPage, query]); // Only re-fetch when perPage or query changes

    const CustomTitle = ({ title }) => (
      <div className="custom-title">{title}</div>
    );

    return (
      <DataTable
        title={title ? <CustomTitle title={title} /> : null}
        columns={columns}
        data={data}
        progressPending={loading}
        progressComponent={<Loading />}
        persistTableHead
        pagination={showPagination}
        paginationServer
        paginationPerPage={perPage}
        paginationRowsPerPageOptions={[10, 20, 30]}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        customStyles={customStyles}
        className={pageName}
        highlightOnHover
        noDataComponent={<p className="pt-3 pb-2">Records not found</p>}
      />
    );
  }
);

DataTablePagination.displayName = "DataTablePagination";

export default DataTablePagination;
