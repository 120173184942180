import React, { useEffect, useState } from "react";
import { deleteApi, post, put } from "../../components/api";
import { showConfirmDialog, SweetAlert } from "../../helpers/helper";
import debounceSearchParams from "../../components/debounce";
import ResponsiveTable from "../../components/ResponsiveTable";
import { FaTrashAlt, FaUserPlus } from "react-icons/fa";
import { TbUserEdit } from "react-icons/tb";
import { SiAuthelia } from "react-icons/si";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ReactSelect } from "../../components/ReactSelect";
import { Modal, Form, Button } from "react-bootstrap";
const User = () => {
  const ftmUserData = JSON.parse(localStorage.getItem("ftmUserData"));
  const [pageData, setPageData] = useState({
    docs: [],
    totalDocs: 0,
    limit: 10,
    totalPages: 1,
    page: 1,
    pagingCounter: 1,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  });
  const [filters, setFilters] = useState({
    options: {
      page: 1,
      limit: 10,
    },
    query: {
      search: "",
    },
    companyId: ftmUserData.id,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [role, setRole] = useState("");
  const roles = [
    { _id: "accountant", name: "accountant" },
    { _id: "operation manager", name: "operation manager" },
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setUser] = useState("");
  const [accessUser, setAccessUser] = useState("");
  const [IsAccessOpen, setIsAccessOpen] = useState(false);
  const [checkedItems, setCheckedItems] = useState({
    Dashboard: false,
    Vehicles: false,
    Geofence: false,
    Fleetreport: false,
    Myorder: false,
    Pricingplan: false,
    Documents: false,
    Tripcalculator: false,
    Users: false,
    Support: false,
  });
  // ["Dashboard","Vehicles","Geofence","Fleetreport","Myorder","Pricingplan","Documents","Tripcalculator","Users","Support"]

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await post("/admin/users", filters);
      setPageData(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filters.options.page, filters.options.limit]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      debounceSearchParams(fetchData, 1000);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [filters.query.search]);

  useEffect(() => {
    if (user) {
      setIsEditMode(true);
      formik.setValues({
        name: user.name || "",
        phonenumber: user.phonenumber || "",
        // Password should be handled carefully
        role:
          roles.find((role) => {
            if (role._id === user.role) {
              setRole(role);
              return role._id;
            }
          }) || "",
        gender: user.gender || "",
        isEdit: isEditMode || false,
      });
    } else {
      setIsEditMode(false);
      formik.resetForm(); // Reset form for adding a new user
    }
  }, [user]);

  // const updatePaginationOptions = (options) => {
  //   setFilters((prevFilters) => ({
  //     ...prevFilters,
  //     options: {
  //       ...prevFilters.options,
  //       page: options.page,
  //       limit: options.sizePerPage,
  //     },
  //   }));
  // };

  const openDeleteDialog = async (id) => {
    const confirmed = await showConfirmDialog(
      "Delete!",
      "Are you sure you want to delete this user?"
    );
    if (confirmed) {
      try {
        const response = await deleteApi(`/admin/delete/user/${id}`);
        if (response.data.status === 200) {
          setPageData((prevPageData) => ({
            ...prevPageData,
            docs: prevPageData.docs.filter((item) => item._id !== id),
          }));
          SweetAlert(response.data.message, false);
        } else {
          SweetAlert(response.data.message, true);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleSearchEvent = (value) => {
    setFilters((prevState) => ({
      ...prevState,
      query: {
        search: value,
      },
    }));
  };

  const handleUpdate = (user) => {
    setUser(user);
    setIsEditMode(true);
    setIsModalOpen(true);
  };
  const handleAccess = (user) => {
    setAccessUser(user);
    setIsAccessOpen(true);
  };
  const rows = pageData.docs.map((user) => ({
    ...user,
    actions: (
      <>
        <button
          className="action-access-button"
          onClick={() => handleAccess(user)}
        >
          <SiAuthelia size={"15px"} />
        </button>
        <button
          className="action-button"
          onClick={() => openDeleteDialog(user._id)}
        >
          <FaTrashAlt size={"13px"} />
        </button>
        <button
          className="action-update-button"
          onClick={() => handleUpdate(user)}
        >
          <TbUserEdit size={"15px"} />
        </button>
      </>
    ),
  }));
  const handleAddUser = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    formik.setValues({
      name: "",
      phonenumber: "",
      password: "",
      role: "",
      gender: "",
      isEdit: false,
    });
    setIsModalOpen(false);
    setIsEditMode(false);
  };
  const closeAccessModal = () => {
    setAccessUser({});
    setIsAccessOpen(false);
    setCheckedItems({
      Dashboard: false,
      Vehicles: false,
      Geofence: false,
      Fleetreport: false,
      Myorder: false,
      Pricingplan: false,
      Documents: false,
      Tripcalculator: false,
      Users: false,
      Support: false,
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleAccessSubmit = async () => {
    const selectedItems = Object.keys(checkedItems).filter(
      (item) => checkedItems[item]
    );
    if (selectedItems.length === 0) {
      SweetAlert("select atleast one checkbox", true);
    } else if (selectedItems.length > 0) {
      await put(`/admin/update/user/${accessUser._id}`, {
        access: selectedItems,
      });
      closeAccessModal();
    }
  };

  const userSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(2, "Name is too short")
      .max(30, "Name is too long"),
    phonenumber: Yup.string()
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
      .required("Phone number is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .max(20, "Maximum 20 characters allowed")
      /* eslint-disable no-useless-escape */
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain One Uppercase, One Lowercase, One Number and One Special Character"
      ),
      /* eslint-disable no-useless-escape */
    role: Yup.string().required("Role is required"),
    gender: Yup.string().required("Gender is required"),
    isEdit: Yup.boolean(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phonenumber: "",
      password: "",
      role: "",
      gender: "",
      isEdit: isEditMode || false,
    },
    validationSchema: userSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      if (isEditMode) {
        try {
          const response = await put(`/admin/update/user/${user._id}`, {
            ...values,
          });

          if (response.status === 200) {
            SweetAlert("User updated successfully.", false);
            setIsModalOpen(false);
            await fetchData();
          } else {
            SweetAlert(response.message, true);
          }
        } catch (ex) {
          console.error(ex);
        } finally {
          setSubmitting(false);
        }
      } else {
        try {
          const response = await post("/admin/add/user", {
            ...values,
            companyId: ftmUserData.id,
          });

          if (response.status === 200) {
            SweetAlert("User added successfully.", false);
            setIsModalOpen(false);
            await fetchData();
          } else {
            SweetAlert(response.message, true);
          }
        } catch (ex) {
          console.error(ex);
        } finally {
          setSubmitting(false);
        }
      }
    },
  });

  const selectHandleChange = (name, value) => {
    if (name == "role") {
      setRole(value);
    }
    formik.setFieldValue(name, value._id);
  };

  return (
    <>
      <div className="user-container">
        <div className="user-header">
          <input
            type="text"
            placeholder="Search..."
            onChange={(e) => handleSearchEvent(e.target.value)}
          />
          <button className="add-user-button" onClick={handleAddUser}>
            <FaUserPlus /> Add User
          </button>
        </div>
        {isLoading ? <p>Loading...</p> : <ResponsiveTable data={rows} />}
        <Modal show={isModalOpen} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{isEditMode ? "Edit User" : "Add User"} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="name" className="custom-lable-support">
                    Name
                  </label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    className={`form-control ${
                      formik.touched.name && formik.errors.name
                        ? "is-invalid"
                        : ""
                    }`}
                    {...formik.getFieldProps("name")}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="invalid-feedback">{formik.errors.name}</div>
                  ) : null}
                </div>

                <div className="form-group">
                  <label htmlFor="phonenumber" className="custom-lable-support">
                    Phone Number
                  </label>
                  <input
                    id="phonenumber"
                    name="phonenumber"
                    type="text"
                    className={`form-control ${
                      formik.touched.phonenumber && formik.errors.phonenumber
                        ? "is-invalid"
                        : ""
                    }`}
                    {...formik.getFieldProps("phonenumber")}
                  />
                  {formik.touched.phonenumber && formik.errors.phonenumber ? (
                    <div className="invalid-feedback">
                      {formik.errors.phonenumber}
                    </div>
                  ) : null}
                </div>

                <div className="form-group">
                  <label htmlFor="password" className="custom-lable-support">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    className={`form-control ${
                      formik.touched.password && formik.errors.password
                        ? "is-invalid"
                        : ""
                    }`}
                    // disabled={formik.values.isEdit}
                    // style={{ backgroundColor: formik.values.isEdit ? '#f0f0f0' : 'white' }}
                    {...formik.getFieldProps("password")}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="invalid-feedback">
                      {formik.errors.password}
                    </div>
                  ) : null}
                </div>

                <div className="form-group">
                  <label htmlFor="role" className="custom-lable-support">
                    Role
                  </label>
                  <ReactSelect
                    name="role"
                    value={role}
                    options={roles}
                    handleOnChange={selectHandleChange}
                    isSearchable={true}
                    isLoading={false}
                    noOptionsMessage="Gender not availables."
                    placeholder="Select Gender"
                  />
                  {formik.touched.role && formik.errors.role ? (
                    <div className="invalid-feedback">{formik.errors.role}</div>
                  ) : null}
                </div>
              </div>
              <div className="form-group">
                <label className="custom-lable-support">Gender</label>
                <div>
                  <label className="custom-lable-support">
                    <input
                      type="radio"
                      name="gender"
                      value="Male"
                      className="user-radio"
                      checked={formik.values.gender === "Male"}
                      onChange={formik.handleChange}
                    />
                    Male
                  </label>
                  <label className="custom-lable-support">
                    <input
                      type="radio"
                      name="gender"
                      value="Female"
                      className="user-radio"
                      checked={formik.values.gender === "Female"}
                      onChange={formik.handleChange}
                    />
                    Female
                  </label>
                </div>
                {formik.touched.gender && formik.errors.gender ? (
                  <div className="invalid-feedback">{formik.errors.gender}</div>
                ) : null}
              </div>

              <div className="submit-div">
                <button
                  type="submit"
                  className="user-submit"
                  disabled={formik.isSubmitting}
                >
                  {isEditMode ? "Update" : "Submit"}
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Modal show={IsAccessOpen} onHide={closeAccessModal}>
          <Modal.Header closeButton>
            <Modal.Title>{`Give access to ${accessUser.name}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {Object.keys(checkedItems).map((item) => (
                <Form.Check
                  key={item}
                  type="checkbox"
                  id={item}
                  label={item}
                  name={item}
                  checked={checkedItems[item]}
                  onChange={handleCheckboxChange}
                />
              ))}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeAccessModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleAccessSubmit}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default User;
