import React from "react";
import { InfoWindow, OverlayView } from "@react-google-maps/api";
import RunningVehicle from "../assets/images/running_vehicle.svg";
import IdleVehicle from "../assets/images/idle_vehicle.svg";
import StoppedVehicle from "../assets/images/stopped_vehicle.svg";
const VehicleMarker = ({
  currentVehicleData,
  currentPosition,
  locationData,
  timeFilter,
  handleMarkerClick,
  openInfoWindow,
}) => {
  const iconUrl =
    currentVehicleData?.tripVehicleStatus === "moving"
      ? RunningVehicle
      : currentVehicleData?.tripVehicleStatus === "idle"
      ? IdleVehicle
      : StoppedVehicle;
  const angle = currentPosition?.angle || 0; // Angle you receive from the vehicle data
  console.log("Here", angle);
  return (
    <>
      <OverlayView
        position={currentPosition}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={(width, height) => ({
          x: -(width / 2),
          y: -(height / 2),
        })}
      >
      <div
        className="vehicle-marker"
        style={{
          transform: `translate(-50%, -50%) rotate(${angle}deg)`,
          backgroundImage: `url(${iconUrl})`,
          width: '23px',
          height: '46px',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          position: 'absolute',
          cursor: 'pointer',
          left: '50%',
          top: '50%',
        }}
        onClick={() => handleMarkerClick()}
      />
      </OverlayView>
      {openInfoWindow && (
        <InfoWindow position={currentPosition} onCloseClick={handleMarkerClick}>
          <div
            style={{
              color: "#fff",
              width: "250px",
              display: "flex",
              flexDirection: "row",
              textAlign: "left",
              padding: "5px",
              alignItems: "baseline",
            }}
            className="live"
          >
            <div>
              <button className="speed-button">
                {timeFilter === "today"
                  ? currentPosition?.speed
                  : locationData[locationData.length - 1].speed}
              </button>
            </div>
            <div style={{ padding: "5px" }} className="live">
              <p className="mb-2">{currentVehicleData?.vehicleNo}</p>
              <p>{currentVehicleData?.address}</p>
            </div>
          </div>
        </InfoWindow>
      )}
    </>
    // <Marker
    //   position={{
    //     lat:
    //       timeFilter === "today"
    //         ? currentPosition?.lat
    //         : locationData[locationData.length - 1].lat,
    //     lng:
    //       timeFilter === "today"
    //         ? currentPosition?.lng
    //         : locationData[locationData.length - 1].lng,
    //   }}
    //   options={{
    //     optimized: true,
    //     icon: {
    //       url: iconUrl,
    //       scaledSize: new window.google.maps.Size(20, 50),
    //       rotation: angle,
    //       anchor: new window.google.maps.Point(9, 22.5)
    //     },
    //   }}
    //   onClick={handleMarkerClick}
    // >
    //   {openInfoWindow && (
    //     <InfoWindow onCloseClick={handleMarkerClick}>
    //       <div
    //         style={{
    //           color: "#fff",
    //           width: "250px",
    //           display: "flex",
    //           flexDirection: "row",
    //           textAlign: "left",
    //           padding: "5px",
    //           alignItems: "baseline",
    //         }}
    //         className="live"
    //       >
    //         <div>
    //           <button className="speed-button">
    //             {timeFilter === "today"
    //               ? currentPosition?.speed
    //               : locationData[locationData.length - 1].speed}
    //           </button>
    //         </div>
    //         <div style={{ padding: "5px" }} className="live">
    //           <p className="mb-2">{currentVehicleData?.vehicleNo}</p>
    //           <p>{currentVehicleData?.address}</p>
    //         </div>
    //       </div>
    //     </InfoWindow>
    //   )}
    // </Marker>
  );
};

export default VehicleMarker;