import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import "./styles.css";
import { extractCoordinates } from "../../helpers/helper";
import tripCalculatorSvg from "../../assets/images/tripCalculator/tripVehicleSvg.png";
import MapTollContainer from "../../components/MapTollContainer";
import moment from "moment";
import { post, get } from "../../components/api";
import AutocompleteInput from "../../components/AutocompleteInput";
import Loading from "../../components/Loading";
import { SweetAlert } from "../../helpers/helper";

const tollTypeArr = [
  {value:"2AxlesTruck", label:"Truck - 2 Axles"}, 
  {value:"3AxlesTruck",label:"Truck - 3 Axles"}, 
  {value:"4AxlesTruck", label:"Truck - 4 Axle"}, 
  {value:"5AxlesTruck",label:"Truck - 5 Axle"}, 
  {value:"6AxlesTruck",label:"Truck - 6 Axle"}, 
  {value:"7AxlesTruck",label:"Truck - 7 Axle"}
];

const TripCalculator = () => {
  const customStyles = {
    control: (base) => ({
      ...base,
      background: "rgba(242, 243, 244, 0.5)",
      border: "none",
      outline: "none",
      fontSize: "0.8rem",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      fontSize: "0.8rem",
    }),
  };
  const [routes, setRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [selectedRouteIndex, setSelectedRouteIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTollVehicle, setselectedTollVehicle] = useState([{
    value: "Toll_Type",
    label: "Select Vehicle Toll Type",
  }]);
  const [mileage, setMileage] = useState('');
  const [selectedOption, setSelectedOption] = useState({
    value: "",
    label: "Select Vehicle",
  });
  const [origin, setOrigin] = useState({ lat: '', lng: '' });
  const [destination, setDestination] = useState({ lat: '', lng: '' });
  const [originAddress, setOriginAddress] = useState('');
  const [destinationAddress, setDestinationAddress] = useState('');
  const [showTripCalc, setShowTripCalc] = useState(false);
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    getVehicles();
  }, []);

  const getVehicles = async () => {
    const vehiclesData = await get("/admin/vehicles-list");
    if (vehiclesData.status === 200) {
        const vehicleList = [];
        if (vehiclesData.data.length > 0) {
            vehiclesData.data.map((vehicle) => {
                vehicleList.push({
                    label: vehicle.vehicleNo,
                    value: vehicle._id,
                });
            });
        }
        setVehicles(vehicleList);
    } else {
        setVehicles([]);
    }
  };

  const handleChange = (selectedOption, type) => {
    if (type == "vehicle") {
      setSelectedOption(selectedOption);
    } else if (type == "Toll") {
      setselectedTollVehicle(selectedOption)
    }
  };

  const handleCreate = (inputValue) => {
    const newOption = { value: "", label: inputValue };
    setSelectedOption(newOption);
  };

  const changeRoute = (route, index) => {
    setSelectedRoute(route);
    setSelectedRouteIndex(index);
  }

  const handlePlaceSelected = (place, inputIdentifier) => {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    const coords = { lat, lng };
    if (inputIdentifier === 'origin') {
      setOrigin(coords);
      setOriginAddress(place?.formatted_address);
    } else {
      setDestination(coords);
      setDestinationAddress(place?.formatted_address);
    }
  };

  const generateRoute = async () => {
    if (origin.let === "" || origin.lng === "") {
      SweetAlert("Orign is required", true);
    } else if (destination.lat === "" || destination.lng === "") {
      SweetAlert("Destination is required", true);
    } else if (selectedTollVehicle && selectedTollVehicle?.value === "") {
      SweetAlert("Vehicle type is required", true);
    } else if (mileage == "") {
      SweetAlert("Milege is required", true);
    } else {
      setIsLoading(true);
      const obj = {
        from: origin,
        to: destination,
        truck: {
          truckRestrictionPenalty: "soft",
        },
        vehicle: {
          type: selectedTollVehicle.value
        },
        departure_time: `${moment().utc()}`,
        mileage : mileage != "" ? parseFloat(mileage) : 3.5,
      };
      const tripCalcApiRes = await post("/calculate-trip", obj);
      console.log(obj)
      if (tripCalcApiRes.apiRes?.status == "OK") {
        let routes = tripCalcApiRes.apiRes.routes;
        if (Array.isArray(routes) && routes.length > 0) {
          let updatedRoutes = routes.map((data) => {
            let { origin, destination, waypoints } = extractCoordinates(
              data.summary.url
            );
            data.summary.origin = origin;
            data.summary.destination = destination;
            data.summary.waypoints = waypoints;
            return data;
          });
          setRoutes(updatedRoutes);
          setSelectedRoute(updatedRoutes[0]);
          setShowTripCalc(true);
          setIsLoading(false);
        } else {
          setRoutes([]);
          setSelectedRoute(null);
          setShowTripCalc(false);
          setIsLoading(false);
        }
      } else {
        setShowTripCalc(false);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="tripCalculatorWrapper">
        <div className="row">
          <div className="tripForm1">
            <div className="row">
            <div className="col-md-4 col-lg-4">
                <div className="formInputWrapper">
                  <p className="reportsFormLabel mb-1">Select Origin</p>
                  <div className="selectWrapperFull">
                    <AutocompleteInput 
                      name="origin"
                      value={origin}
                      onPlaceSelected={handlePlaceSelected}  
                      className="userInput" 
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4">
                <div className="formInputWrapper">
                  <p className="reportsFormLabel mb-1">Select Destination</p>
                  <div className="selectWrapperFull">
                    <AutocompleteInput 
                      name="destination"
                      value={destination}
                      onPlaceSelected={handlePlaceSelected}  
                      className="userInput" 
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4">
                <div className="formInputWrapper">
                  <p className="reportsFormLabel mb-1">Enter Vehicle No</p>
                  <div className="selectWrapperFull">
                    <CreatableSelect
                        placeholder="Select or create a vehicle"
                        value={selectedOption}
                        onChange={(e) => handleChange(e, "vehicle")}
                        onCreateOption={(e) => handleCreate(e)}
                        options={vehicles}
                        styles={customStyles}
                        isClearable={true}
                        classNamePrefix="react-select"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4">
                <div className="formInputWrapper">
                  <p className="reportsFormLabel mb-1">Enter Mileage</p>
                  <div className="selectWrapperFull">
                    <input
                      placeholder="Mileage"
                      value={mileage}
                      onChange={(e) => setMileage(e.target.value)}
                      className="userInput"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4">
                <div className="formInputWrapper">
                  <p className="reportsFormLabel mb-1">Select Vehicle Toll Type</p>
                  <div className="selectWrapperFull">
                    <Select
                      value={selectedTollVehicle}
                      onChange={(e) => handleChange(e, "Toll")}
                      options={tollTypeArr}
                      defaultValue={{
                        value: "Toll_Type",
                        label: "Select Vehicle Toll Type",
                      }}
                      styles={customStyles}
                    />
                  </div>
                  </div>
              </div>
              <div className="btnFlex">
                <div className="btn-wrapper-trip" onClick={generateRoute}>
                  <a className="reportGenBtn">
                    Submit
                  </a>
                </div>
              </div>
            </div>
          </div>
          {!isLoading ? (
            <>
              {showTripCalc ? (
                <>
                  <div className="tripForm2">
                    <div className="row">
                      <div className="col-md-4 col-lg-4 col-xs-12" style={{paddingLeft:"0px"}}>
                        <div className="row">
                          <div className="col-md-12 col-lg-12">
                            <div className="detailsWrapper">
                              <p className="detailsHead">Trip Details</p>
                              <p className="tripVehicleNo">{selectedOption?.label}</p>
                              <img
                                className="tripSvg"
                                src={tripCalculatorSvg}
                              />
                              <div className="tripOtherDetailsWrapper">
                                <div className="tripOtherDetail mb-3">
                                  <div className="from">
                                    <p className="fromText">From</p>
                                    <p className="fromLocation">
                                      {originAddress}
                                    </p>
                                  </div>
                                  <div className="to">
                                    <p className="toText">To</p>
                                    <p className="toLocation">
                                      {destinationAddress}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between">
                                <div>
                                  <p className="mb-0 root-title">Trip Toll Cost (Cash) </p>
                                  <p className="root-value">&#8377;{selectedRoute?.costs?.cash}</p>
                                </div>
                                <div>
                                  <p className="mb-0 root-title">Total Fuel Cost </p>
                                  <p className="root-value text-right">&#8377;{selectedRoute?.costs?.fuel}</p>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between">
                                <div>
                                  <p className="mb-0 root-title">Trip Toll Cost (FastTag) </p>
                                  <p className="root-value">&#8377;{selectedRoute?.costs?.tag}</p>
                                </div>
                                <div>
                                  <p className="mb-0 root-title">Total Distance </p>
                                  <p className="root-value text-right">{selectedRoute?.summary?.distance?.metric}</p>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between">
                                <div>
                                  <p className="mb-0 root-title">Estimated Time </p>
                                  <p className="root-value">{selectedRoute?.summary?.duration?.text}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-8 col-xs-12" style={{paddingRight: "0px"}}>
                        <div className="row">
                          <div className="col-md-12 col-lg-12 col-xs-12">
                            <div className="routeWrapper">
                              {routes.map((route, index) => (
                                <Button variant={selectedRouteIndex === index ? 'primary' : 'outline-primary'} key={index + 1} onClick={() => changeRoute(route, index)} className="custom-route-btn">
                                  Route {index + 1}: {route?.tolls?.length} tolls
                                </Button>
                              ))}
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-12">
                            <div className="mapWrapper">
                              <MapTollContainer selectedRoute={selectedRoute} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </>
  );
};

export default TripCalculator;
