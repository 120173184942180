import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { get } from "./api";
import Select from "react-select";
import "./style.css";

const Breadcrumb = () => {
  const navigate = useNavigate();
  const url = useLocation();
  const profileRegex = /\profile\//;
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const pathnameWithoutSlash = location.pathname.substring(1);
  const [vehicle, setVehicle] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const [vehicleOption, setVehicleOption] = useState([]);
  // const vehicleId  = useParams();

  useEffect(() => {
    const getVehicles = async () => {
      const vehiclesData = await get("/admin/vehicles-list");
      if (vehiclesData.status == 200) {
        setVehicles(vehiclesData.data);
      } else {
        setVehicles([]);
      }
    };
    if (!url.pathname.includes("/share")) {
      getVehicles();
    }
  }, []);

  useEffect(() => {
    setVehicleOption(createVehicleOption());
  }, [vehicles]);

  const createVehicleOption = () => {
    return vehicles.map((vehicle) => ({
      value: `${vehicle.vehicleNo}`,
      label: `${vehicle.vehicleNo}`,
      id: vehicle._id,
    }));
  };

  const handleVehicleNo = (vehicleObj) => {
    if (vehicle.value !== vehicleObj.value) {
      setVehicle(vehicleObj);
      navigate(`/vehicles/${vehicleObj.value}`, {
        state: { id: vehicleObj.id },
      });
    }
  };

  function matchVehiclesString(url) {
    const regex = /vehicles\//; // Define regex pattern to match "vehicles/"
    if (regex.test(url)) {
      // Check if the URL contains "vehicles/"
      return true;
    } else {
      return false;
    }
  }

  function matchPlaybackString(url) {
    const regex = /playback\//;
    if (regex.test(url)) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    setVehicleOption(createVehicleOption());
  }, [vehicles]);

  const customStyles = {
    container: (base) => ({
      ...base,
      zIndex: 1
    }),
    control: (base) => ({
      ...base,
      width: "200px",
      padding: 0, // Remove padding to eliminate white space
      fontSize: "0.8rem",
      textAlign: "left"
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
      fontSize: "0.8rem",
      textAlign: "left",
    }),
  };

  const getTitle = (pathname) => {
    const titles = {
      "fleet-reports": "Fleet Reports",
      "plans/buy-gps": "Pricing Plans",
      "plans/buy-gps-fuel": "Pricing Plans",
      "trip-calculator": "Trip Calculator",
      "faqs": "FAQs",
      "fleet-reports/today-report": "Fleet Reports",
      "fleet-reports/monthly-report": "Fleet Reports",
      "fleet-reports/fuel-consuption-report": "Fleet Reports",
      "fleet-reports/idle-duration-report": "Fleet Reports",
      "fleet-reports/ignition-report": "Fleet Reports",
      "fleet-reports/overspeeding-report": "Overspeeding Reports",
      "geofence/create": "Geofence",
    };

    if (titles[pathname]) {
      return titles[pathname];
    } else if (profileRegex.test(pathname)) {
      return "My Profile";
    } else if (matchVehiclesString(pathname)) {
      return "Vehicle Info";
    } else if (matchPlaybackString(pathname)) {
      return "Playback";
    } else {
      return pathname;
    }
  };
  const title = getTitle(pathnameWithoutSlash);

  useEffect(() => {
    {
      if (matchVehiclesString(pathnameWithoutSlash)) {
        const url = { pathnameWithoutSlash };
        const parts = url.pathnameWithoutSlash.split("/"); // Split the string by '/'
        const vehicleId = parts[1];
        const onPageVehicle = vehicleOption.find(
          (vehicle) => vehicle.value == vehicleId
        );
        setVehicle(onPageVehicle);
      }
    }
  });

  return (
    <div className="breadcrumb-parent">
      <div className="ForvehicleContainer">
        <h6 className="breadcrumb-title text-left">{title}</h6>
        {matchVehiclesString(pathnameWithoutSlash) && (
          <Select
            styles={customStyles}
            options={vehicleOption}
            onChange={(e) => handleVehicleNo(e)}
            value={vehicle}
            maxMenuHeight={150}
            placeholder="Select your vehicle"
          />
        )}
      </div>
      {pathnameWithoutSlash !== "dashboard" && (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            {pathnames.map((path, index) => {
              if (path !== "plans") {
                const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
                let stringWithSpaces = path.replace(/[-]/g, " ");
                const capitalizedPath = stringWithSpaces
                  .toLowerCase()
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ");
                const isLast = index === pathnames.length - 1;
                return (
                  <li
                    key={path}
                    className={`breadcrumb-item ${isLast ? "active" : ""}`}
                  >
                    {isLast ? (
                      capitalizedPath
                    ) : (
                      <Link to={routeTo}>{capitalizedPath}</Link>
                    )}
                  </li>
                );
              }
            })}
          </ol>
        </nav>
      )}
    </div>
  );
};

export default Breadcrumb;
