import React from "react";
import SVG from "react-inlinesvg";
import redSquare from "../assets/svg/stoppedVehicle.svg";

const StopHistory = (props) => {
  let { stopData } = props;
  return stopData.length > 0 ? (
    <>
      {stopData.map((element, i) => (
        <div style={{ display: "flex", flexDirection: "column" }} key={i}>
          <div
            className="vehicleStatusSvg"
            style={{ display: "flex", alignItems: "center", height: "30px" }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={redSquare} height={20} width={20} />
            </span>
            <div
              className="stoppedStatusAndTime"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <p
                style={{
                  color: "rgba(226, 71, 63, 1)",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  marginBottom: 0,
                }}
              >
                Stopped
              </p>
              <p
                style={{
                  color: "rgba(120, 117, 117, 1)",
                  paddingInline: "1rem",
                  paddingTop: "0.25rem",
                  fontSize: "0.7rem",
                  fontWeight: "200",
                  marginBottom: 0,
                }}
              >
                {element.idleTime}
              </p>
            </div>
          </div>
          <div>
            <div
              className="add-detials"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <p
                style={{
                  color: "rgba(120, 117, 117, 1)",
                  fontSize: "0.7rem",
                  display: "block",
                  textAlign: "left",
                  marginBottom: "5px",
                }}
              >
                {element.address}
              </p>
              <p
                style={{ color: "rgba(120, 117, 117, 1)", fontSize: "0.7rem" }}
              >
                {element.startTimeStamp} {" - "} {element.endTimeStamp}
              </p>
            </div>
          </div>
          <hr />
        </div>
      ))}
    </>
  ) : (
    <p>No route history found</p>
  );
};

export default StopHistory;
