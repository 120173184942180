import {useEffect, useState} from "react";
import englishJson from '../assets/englishTranlation.json';
import hindiJson from "../assets/hindiTranslation.json";

const translate = (key, language) => {
  // return the translated text or the original text
  if (language == 'hindi') {
    return hindiJson[key]
  } else {
    return englishJson[key]
  }
};

const Translate = ({ children }) => {
    const [language, setLanguage] = useState("");

    window.addEventListener('storage', ()=> {
      let updatedLang = localStorage.getItem('language');
      setLanguage(updatedLang)
    })

    useEffect(()=> {
      let initialLanguage = localStorage.getItem('language')
      setLanguage(initialLanguage)
    }, [language])

  return translate(children, language);
};

export default Translate;
