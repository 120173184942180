import React from "react";
import Spinner from 'react-bootstrap/Spinner';

const Loading = () => {
    return (
        <>
            <div style={{ "display": "flex", "alignItems": "center", "justifyContent": "center", "height": "25vh", "width": "100%" }}>
                <Spinner size="md" color="red" />
            </div>
        </>
    )
}

export default Loading;