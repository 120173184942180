import React, { useState, useRef, useEffect } from "react";
import Select, { components } from "react-select";
import { Link, useNavigate } from "react-router-dom";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import { connect, useSelector, useDispatch } from "react-redux";
import { post } from "../../components/api"
import {
  changeLanguageEnglish,
  changeLanguageHindi,
} from "../../redux/actions/languageActions";
import NotificationWithOutBadge from "../../assets/topbarIcons/NotificationWithOutBadge.png";
import settingIcon from "../../assets/profile/settings.png";
import signoutIcon from "../../assets/profile/sign_out.png";
import profileAvtar from "../../assets/profile/sample_profile.png"
import "./style.css";
import moment from 'moment'

const Topbar = () => {
  let userData = localStorage.getItem('ftmUserData');
  let fcmWebToken = localStorage.getItem('fcmWebToken');
  const navigate = useNavigate();
  let { Option } = components;
  const [show, setShow] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationTarget, setNotificationTarget] = useState(null)
  const [target, setTarget] = useState(null);
  const refSettingsOverlay = useRef(null);
  const refNotificationOverlay = useRef(null)
  const dispatchAction = useDispatch();
  const callback = (state) => state;
  const states = useSelector(callback);
  const [className, setClassName] = useState("topbar");
  const [notificationData, setNotificationData] = useState([])

  if (userData) {
    try {
      userData = JSON.parse(userData);
    } catch (error) {
      console.error('Error parsing user data:', error);
    }
  } else {
    console.log('No user data found in localStorage');
    navigate('/login');
  }

  const fetchNotification = async () => {
    const getNotification = await post('/admin/notification-list', {});

    if (getNotification.status == 200) {
      setNotificationData(getNotification.data)
    } else {
      setNotificationData([])
    }
  }

  useEffect(() => {
    if (!window.location.pathname.includes("/share")) {
      fetchNotification();
    }
    const getLanguage = localStorage.getItem('language');
    if (getLanguage == null) {
      localStorage.setItem('language', "english")
      setSelectedOption({
        value: "english",
        label: "English"
      })
    } else {
      if (getLanguage == "hindi") {
        setSelectedOption({
          value: "hindi",
          label: "Hindi"
        })
      } else {
        setSelectedOption({
          value: "english",
          label: "English"
        })
      }
    }
  }, [])

  useEffect(() => {
    if (states?.collapsable?.collapsed == true) {
      setClassName("topbarCollapsed");
    } else {
      setClassName("topbar");
    }
  }, [states.collapsable.collapsed]);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleNotificationClick = (event) => {
    setShowNotification(!showNotification);
    setNotificationTarget(event.target)
  }

  const options = [
    { value: "english", label: "English" },
    { value: "hindi", label: "Hindi" },
  ];

  const [selectedOption, setSelectedOption] = useState({
    value: "english",
    option: "English",
  });

  const Options = (props) => {
    return (
      <Option {...props}>
        <div className="selectInternalWrap">
          <p className="selectInputText">{props?.data?.label}</p>
        </div>
      </Option>
    );
  };

  const handleChange = (selectedOption) => {
    if (selectedOption.value == "english") {
      setSelectedOption({ ...selectedOption, icon: "engImage.png" });
      dispatchAction(changeLanguageEnglish("english"));
      localStorage.setItem("language", 'english');
      window.dispatchEvent(new Event('storage'))
    } else {
      setSelectedOption({ ...selectedOption, icon: "indImage.png" });
      dispatchAction(changeLanguageHindi("hindi"));
      localStorage.setItem('language', 'hindi');
      window.dispatchEvent(new Event('storage'))
    }
    //setSelectedOption(selectedOption);
  };

  const signOut = async () => {
    const apiResponse = await post("/auth/logout-admin",{'fcmWebToken' : fcmWebToken})
    console.log(apiResponse.status)
    localStorage.removeItem("ftmUserData");
    localStorage.removeItem("loginToken");
    navigate("/login");
  };
  
  return (
    <div className={className}>
      <div className="topbar-wrapper">
        <div className="select-wrapper" style={{width: '110px', fontSize: '14px'}}>
          <Select
            value={selectedOption}
            onChange={(e) => handleChange(e)}
            options={options}
            components={{
              Option: Options,
              //SingleValue: SingleValues
            }}
            defaultValue={options[0]}
          />
        </div>
        <div
          ref={refNotificationOverlay}
          onClick={handleNotificationClick}
          style={{ cursor: "pointer" }}
        >
          <img className="icon-style" src={NotificationWithOutBadge} />{" "}

          <Overlay
            show={showNotification}
            target={notificationTarget}
            placement="bottom"
            container={refNotificationOverlay}
            containerPadding={20}
            rootClose={true}
            onHide={() => setShowNotification(false)}
          >
            <Popover id="popover-contained-notification">
              <div
                className="notificationPopover-wrapper"
                style={{ cursor: "initial" }}
              >
                <div className="heading">
                  <p className="headingCss">Notifications</p>
                  <hr></hr>
                </div>
                {notificationData.length > 0 ?
                  (
                    <>
                      {notificationData.map((notification, index) => (
                        <div key={`notification-${index}`}>
                          <div>
                            <div className="notificationHeader">
                              <p className="notificationTitle">{notification.title}</p>
                              <p className="notificationDateTime">Today {moment(notification.timeStamp).format("hh:mm a")}</p>
                            </div>
                            <p className="notificationMsg">{notification.message}</p>
                          </div>
                          <hr></hr>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <div style={{ "display": "flex", "alignItems": "center", "justifyContent": "center", 'marginTop': "1rem" }}>
                        <p style={{ "marginBlock": "0.3rem", "fontWeight": "500", "color": "#585858", "fontSize": "1rem" }}>No Notifications to show!</p>
                      </div>
                    </>
                  )}
              </div>
            </Popover>
          </Overlay>
        </div>
        <div
          className="profile-link"
          ref={refSettingsOverlay}
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        >
          <img src={userData?.photo ? userData?.photo: profileAvtar} alt="" id="profile-img" className="profile-img" />
          <Overlay
            show={show}
            target={target}
            placement="bottom"
            container={refSettingsOverlay}
            containerPadding={20}
            rootClose={true}
            onHide={() => setShow(false)}
          >
            <Popover id="popover-contained">
              <div
                className="settingPopover-wrapper"
                style={{ cursor: "initial" }}
              >
                {userData !== undefined && userData !== null && userData !== '' && <div className="profileHeader">
                  <Link to={'/profile/' + userData.id} className="profile-img-link">
                    <img src={userData?.photo ? userData?.photo: profileAvtar} className="profile-img" id="profile-img" />
                  </Link>
                  <div className="profileDetails">
                    <Link to={'/profile/' + userData?.id} className="text-decoration-none">
                      <p className="userName">{userData?.ownerName}</p>
                    </Link>
                    <p className="userEmail">{userData?.email}</p>
                  </div>
                </div>}
                <hr></hr>
                <Link to={'/profile/' + userData?.id} className="text-decoration-none">
                  <div className="menuWrapper">
                    <img src={settingIcon} className="profileIconStyle" />
                    <p className="profileKey">My Profile</p>
                  </div>
                </Link>
                <hr></hr>
                <Link to={"/settings"} className="text-decoration-none">
                  <div className="menuWrapper">
                    <img src={settingIcon} className="profileIconStyle" />
                    <p className="profileKey">Settings</p>
                  </div>
                </Link>
                <hr></hr>
                <div
                  className="menuWrapper"
                  style={{ marginBottom: "0.5rem" }}
                  onClick={signOut}
                >
                  <img src={signoutIcon} className="profileIconStyle" />
                  <p className="profileKey">Sign Out</p>
                </div>
              </div>
            </Popover>
          </Overlay>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => (
  {
    collapsed: state.collapsed,
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    handleCollapse: () => dispatch({ type: "collapsed" }),
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
