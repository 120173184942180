import React, { useState } from "react";
import { Modal, Button, Form, Badge, CloseButton } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import { deleteApi, post } from "../components/api";
import { showConfirmDialog, SweetAlert } from "../helpers/helper";

const DialogBox = ({
  show,
  onHide,
  vehicleId,
  vehicleNo,
  handleAddTag,
  tags,
  updateTag,
}) => {
  const [selectedTags, setSelectedTags] = useState([]);

  const tagOptions = tags;
  const handleChange = (newValue) => {
    setSelectedTags(newValue);
  };

  const handleCreate = (inputValue) => {
    if (inputValue !== "") {
      const newOption = { tagname: inputValue, _id: null };
      setSelectedTags([...selectedTags, newOption]);
    } else if (inputValue === "") {
      SweetAlert("tag is required", false);
    }
  };

  const handleSubmit = async (e) => {
    if (selectedTags.length === 0) {
      SweetAlert("tag is required", true);
    } else if (
      selectedTags.some((selectedTag) => selectedTag.tagname.length < 3)
    ) {
      SweetAlert("tag must have more than 2 letters", true);
    } else {
      e.preventDefault();
      try {
        const apiResponse = await post("admin/addtag", {
          tags: selectedTags,
          vehicleid: vehicleId,
        });
        if (apiResponse?.status === 200) {
          SweetAlert(apiResponse?.message, false);
        } else if (apiResponse?.status === 201) {
          SweetAlert(apiResponse?.message, true);
        }
      } catch (error) {
        console.log(error);
      }
      setSelectedTags([]);
      onHide();
      handleAddTag(vehicleId);
    }
  };

  const handleRemoveTag = async (id, vehicleId) => {
    const confirmed = await showConfirmDialog(
      "Delete!",
      "Are you sure you want to delete tag?"
    );
    if (confirmed) {
      try {
        await deleteApi(`admin/deleteTag/${id}`);
      } catch (error) {
        console.log(error);
      }
      updateTag(id, vehicleId);
    }
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Tag to Vehicle: {vehicleNo} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formNewTag">
            <CreatableSelect
              isMulti
              isClearable
              getOptionLabel={(option) => option.tagname}
              getOptionValue={(option) => option._id}
              value={selectedTags}
              onChange={handleChange}
              onCreateOption={handleCreate}
              options={tagOptions}
              placeholder="Enter or create tags"
            />
          </Form.Group>
        </Form>
        <div className="mt-3">
          <h6>Existing Tags:</h6>
          {tags?.map((tag, index) => (
            <Badge bg="success" className="m-1" key={index}>
              {tag?.tagname}
              <CloseButton
                className="closebtn"
                variant="white"
                onClick={() => handleRemoveTag(tag._id, vehicleId)}
                style={{ fontSize: "10px", marginLeft: "2px" }}
              />
            </Badge>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="success" type="submit" onClick={handleSubmit}>
          Add Tag
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DialogBox;
