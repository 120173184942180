import React, { useState } from "react";
import {
  GoogleMap,
  Polyline,
  Marker,
  InfoWindow
} from "@react-google-maps/api";
import stopIcon from "../assets/vehicles/stoppedHistory.svg";
import RunningVehicle from "../assets/images/running_vehicle.png";
import IdleVehicle from "../assets/images/idle_vehicle.png";
import StoppedVehicle from "../assets/images/stopped_vehicle.png";

const MapComponent = ({ locationData, stopData }) => {
  const [progress] = useState(locationData); // Use locationData as the initial path
  const [markerPosition] = useState({
    lat: locationData[locationData.length - 1].lat,
    lng: locationData[locationData.length - 1].lng,
    timeStamp: locationData[locationData.length - 1].timeStamp,
    fuel: locationData[locationData.length - 1].fuel || "",
    ignition: locationData[locationData.length - 1].ignition,
    speed: locationData[locationData.length - 1].speed,
    address: locationData[locationData.length - 1].address,
  });
  const [openInfoWindow, setOpenInfoWindow] = useState(null);
  const [currentLocation, setCurrentLocation] = useState({
    address: "",
    speed: "",
  });

  const path = locationData; // Define the path for the polyline
  const stopLocations = stopData;

  const handleMarkerClick = (position, index) => {
    console.log("Clicked Stop Marker:", index, position);
    setCurrentLocation({
      speed: position.speed || "0",
      address: position.address,
    });
    setOpenInfoWindow(index);
  };

  let vehicleStatus = "";
  if (progress.length > 0) {
    vehicleStatus =
      progress[progress.length - 1].speed === 0 &&
      progress[progress.length - 1].ignition === true
        ? "idle"
        : progress[progress.length - 1].speed > 0 &&
          progress[progress.length - 1].ignition === true
        ? "running"
        : "stopped";
  } else {
    vehicleStatus =
      locationData[locationData.length - 1].speed === 0 &&
      locationData[locationData.length - 1].ignition === true
        ? "idle"
        : locationData[locationData.length - 1].speed > 0 &&
          locationData[locationData.length - 1].ignition === true
        ? "running"
        : "stopped";
  }

  return (
      <GoogleMap
        zoom={16}
        center={{ lat: path[path.length - 1].lat, lng: path[path.length - 1].lng }}
        options={{ fullscreenControl: false }}
        mapContainerStyle={{ height: "100vh", width: "100%" }}
      >
        {progress && (
          <>
            {/* Use Polyline directly with the locationData path */}
            <Polyline path={progress} options={{ strokeColor: "#0044ff", strokeWeight: 2 }} />
            
            {/* Marker for each stop location */}
            {stopLocations.map((stop, index) => (
              <Marker
                key={index}
                position={{ lat: stop.coordinates[0], lng: stop.coordinates[1] }}
                label={{ text: `${index + 1}`, color: "white" }}
                icon={{ url: stopIcon, scaledSize: { width: 36, height: 36 } }}
                onClick={() =>  handleMarkerClick(stop,index)}
              >
                {console.log(openInfoWindow === index)}
                 {openInfoWindow === index && (
                 <InfoWindow
                position={{ lat: stop.coordinates[0], lng: stop.coordinates[1] }}
                onCloseClick={() => setOpenInfoWindow(null)}
                // anchor={mapRef.current} // Anchor InfoWindow to the map instance
              >
                  <div style={{ color: "#fff", width: "280px" }} className="stops">
                    <p>{stop.idleTime}</p>
                    <p>
                      {stop.startTimeStamp} - {stop.endTimeStamp}
                    </p>
                    <p>{stop.address}</p>
                  </div>
                </InfoWindow>
              )}
              </Marker>
            ))}

            {/* Vehicle Marker */}
            <Marker
              position={progress.length > 0 ? progress[progress.length - 1] : markerPosition}
              onClick={() => handleMarkerClick(progress.length > 0 ? progress[progress.length - 1] : markerPosition, -1)}
              icon={{
                url:
                  vehicleStatus === "running"
                    ? RunningVehicle
                    : vehicleStatus === "idle"
                    ? IdleVehicle
                    : StoppedVehicle,
                scaledSize: { width: 50, height: 50 },
              }}
            >
              {openInfoWindow === -1 && (
                <InfoWindow onCloseClick={() => setOpenInfoWindow(null)}>
                  <div style={{ color: "#fff", width: "280px", display: "flex", padding: "5px" }} className="live">
                    <p className="live-speed" style={{marginTop : "20px"}}>{currentLocation.speed}</p>
                    <p style={{marginTop : "20px"}}>{currentLocation.address}</p>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          </>
        )}
      </GoogleMap>
  );
};

export default MapComponent;
