import React from "react";
import { MultiSelect } from "react-multi-select-component";

const Checkbox = ({ options, value, onChange, name = "" }) => {
  // const isAllSelected = value?.length === options?.length;
  // width: "240px",
  // , boxShadow: "rgba(0, 0, 0, 0.25) 0px 2px 5px 0px"
  return (
    <div style={{ borderRadius: "5px" }}>
      <MultiSelect
        options={options}
        value={value}
        onChange={onChange}
        labelledBy="Select Vehicles"
        hasSelectAll={true}
        className="ps-menu-checkbox-label"
        valueRenderer={(selected) => {
          const selectedLength = selected?.length;
          if (selectedLength === 0) {
            return (
              <p
                className="ps-menu-checkbox-label"
                //style={{ marginTop: "16px" }}
              >
                {`Select ${name}....`}
              </p>
            );
          } else if (selectedLength === options?.length) {
            return (
              <p
                className="ps-menu-checkbox-label"
                style={{ marginTop: "16px" }}
              >{`All ${name} selected `}</p>
            );
          } else {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  //justifyContent: "center",
                  justifyContent: "space-between",
                }}
              >
                <p
                  className="ps-menu-checkbox-label"
                  style={{ marginTop: "16px" }}
                >
                  {`Selected ${name}`}
                </p>
                <span
                  style={{
                    // padding: "1px 5px",
                    height: "18px",
                    width: "18px",
                    borderRadius: "50%",
                    backgroundColor: "#2A7C7A",
                    color: "white",
                    fontWeight: "bold",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {selectedLength}
                </span>
              </div>
            );
          }
        }}
      />
    </div>
  );
};

export default Checkbox;
